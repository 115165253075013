import {useLoaderData, useSearchParams} from '@remix-run/react';
import {useEffect, useRef, useState, useCallback} from 'react';
import {ChevronLeftIcon, ChevronRightIcon} from '@shopify/polaris-icons';
import type {LinksFunction} from '@remix-run/cloudflare';

import Pagination, {
  usePagination,
} from '@/components/base/modules/Pagination/Pagination';
import PageLayout from '@/components/shared/Page/PageLayout';
import type {Handle} from '@/types';
import {default as PlusContactModal} from '@/components/plus/section/ContactForm/Modal';
import Section from '@/components/base/layouts/Section/Section';
import HeadingGroup from '@/components/base/modules/HeadingGroup/HeadingGroup';
import {UrlUtils} from '@/utils/UrlUtils';
import type {Site} from '@data/types';
import type {HeaderProps} from '@/components/brochureV2/section/Header/types';

import stylesheet from './styles.css?url';
import {sharedHandle} from './sharedHandle';
import {type loader} from './loader.server';
import Filters from './components/Filters';
import {ARTICLES_PER_PAGE} from './constants';
import {getFilteredArticles} from './utils/getFilteredArticles';
import type {
  IndexArticlesQueryNodes,
  Filters as FiltersType,
} from './typesGraphql';
import {CaseStudies} from './components/CaseStudies';
import CaseStudiesHeader from './components/CaseStudiesHeader';

export {headers} from './headers.server';

export {loader} from './loader.server';

export const links: LinksFunction = () => [
  {rel: 'stylesheet', href: stylesheet},
];

export const handle: Handle = {
  ...sharedHandle,
};

/**
 * Since we are handling all pagination client-side, we don't want Remix to
 * ever revalidate this route to avoid unnecessary re-fetching of loader data.
 *
 * @see https://remix.run/docs/en/main/route/should-revalidate
 */
export function shouldRevalidate() {
  return false;
}

export {combinedLeadAction as action} from '@/utils/server/actions/plus/combinedLeadAction.server';

export default function CaseStudiesIndex() {
  const {
    articles: initialArticles,
    content,
    filters,
    pathPrefix,
    languageCode,
    locale,
    countryRegionMap,
  } = useLoaderData<typeof loader>();
  const [searchParams] = useSearchParams();
  /**
   * Used to control re-renders when `searchParams` changes and on `popState`
   * events. We can't use Remix's `setSearchParams()` from `useSearchParams()`
   * because it causes double the re-renders and makes updates feel sluggish.
   * `searchParams` is convenient to access params, then always use
   * `history.pushState()` to update the URL (never `setSearchParams`).
   */
  const setQueryParams = useState(searchParams.toString())[1];
  const [page, setPage] = useState(
    parseInt(searchParams.get('page') ?? '1', 10),
  );
  const [newPage, setNewPage] = useState(page);
  const urlUtils = new UrlUtils({pathPrefix, languageCode, locale} as Site);
  const fireOnce = useRef(false);
  const filtersWithSelected = filters.map((filter) => ({
    ...filter,
    selected: searchParams.get(filter.handle) ?? '',
  }));
  const filterHandles = filters.map((filter) => filter.handle);
  const validParamsEntries = [...searchParams.entries()].filter(([key]) =>
    filterHandles.includes(key),
  );
  const hasActiveFilters = validParamsEntries.length > 0;
  const filteredArticles = getFilteredArticles(
    // TODO: Remove this assertion later
    initialArticles as IndexArticlesQueryNodes,
    validParamsEntries,
    countryRegionMap,
  );

  const totalPages = Math.ceil(filteredArticles.length / ARTICLES_PER_PAGE);
  const hasPages = totalPages > 1;
  /** Clamped between 1 and `totalPages` */
  const clampedPage = Math.max(1, Math.min(page, totalPages));
  /** Clamped between 1 and `totalPages` */
  const clampedNewPage = Math.max(1, Math.min(newPage, totalPages));
  const paginationRange = usePagination({
    currentPage: clampedPage,
    totalCount: filteredArticles.length,
    siblingCount: 0,
    pageSize: ARTICLES_PER_PAGE,
  });

  /**
   * Listen for `popstate` events to handle back/forward button navigation.
   */
  useEffect(() => {
    function onPopState(e: PopStateEvent) {
      const {page: popPage} = e.state ?? {page: 1};

      if (popPage) {
        const popPgInt = parseInt(popPage, 10);
        setNewPage(popPgInt);
        fireOnce.current = true;
        setQueryParams(new URLSearchParams(e.state ?? {}).toString());
      } else {
        clearPage(searchParams);
        setQueryParams(crypto.randomUUID());
      }
    }

    window.addEventListener('popstate', onPopState);

    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, [searchParams, setQueryParams]);

  function clearPage(params?: URLSearchParams) {
    if (params) params.delete('page');
    setNewPage(1);
    setPage(1);
  }

  const onFilterChange = (fieldName: string, value: string) => {
    if (searchParams.get('page')) {
      clearPage(searchParams);
    }

    if (value) {
      searchParams.set(fieldName, value);
    } else {
      searchParams.delete(fieldName);
    }

    history.pushState(
      Object.fromEntries(searchParams),
      '',
      `?${searchParams.toString()}`,
    );
    setQueryParams(searchParams.toString());
  };

  const clearFilters = () => {
    if (searchParams.size !== 0) {
      [...searchParams.keys()].forEach((key) => {
        searchParams.delete(key);
      });

      history.pushState({}, '', window.location.pathname);
      setQueryParams(searchParams.toString());
    } else {
      /**
       * Query params have already been cleared (possibly via back button), to
       * ensure we reset the UI force an update via `setQueryParams()`.
       */
      setQueryParams(crypto.randomUUID());
    }

    clearPage();
  };

  /**
   * Calls `setNewPage()` to begin the transition to a new page. This interim
   * state is necessary to allow the current page to transition to `opacity-0`
   * and the prev/next page to transition to `opacity-100` before
   * `getArticlesPageSlice()` renders the next set of slices. Once the transition
   * is complete `handleTransitionEnd()` will call `setPage()` to update the
   * more widely used "final" page state.
   */
  const handlePageClick = (pg: number, isBottomPagination = true) => {
    // Strip out any non-filter params
    for (const [param] of searchParams.entries()) {
      if (!filterHandles.includes(param)) {
        searchParams.delete(param);
      }
    }

    searchParams.set('page', pg.toString());

    history.pushState(
      Object.fromEntries(searchParams),
      '',
      `?${searchParams.toString()}`,
    );
    setNewPage(pg);
    fireOnce.current = true;
    if (isBottomPagination) window.scrollTo({top: 0, behavior: 'smooth'});
  };

  /**
   * Completes the transition to a new page upon completion of the current page's
   * transition to `opacity-0`. When this state updates, `getArticlesPageSlice()`
   * will render the correct prev/current/next pages.
   *
   * @note It's important that the `transition-duration` of the current and
   * next/prev pages transitions match for this to function smoothly.
   */
  const handleTransitionEnd = () => {
    if (fireOnce.current) {
      setPage(newPage);
      fireOnce.current = false;
    }
  };

  // Note: This function requires a useCallback to prevent the header and modal from getting stuck in the re-render cycle of this route component which would cause double scroll bars to render while the modal is open.
  const customHeader = useCallback((props: HeaderProps) => {
    return <CaseStudiesHeader {...props} modalOpeningHref="#contact-sales" />;
  }, []);

  return (
    <PageLayout
      header={customHeader}
      title={content.htmlHead.pageTitle}
      hasScrollRestoration
    >
      <PlusContactModal />
      <HeadingGroup
        className="bg-white pb-2xl pt-2xl container"
        kicker={content.hero.kicker}
        headingHtml={content.hero.headingHtml}
        kickerAs="h1"
        headingAs="h2"
      />
      <Section
        topSpacing="2xl"
        bottomSpacing="2xl"
        className="gap-y-lg bg-shade-10 relative"
      >
        {/* Filters */}
        <div className="container flex justify-between">
          <HeadingGroup size="t5" headingHtml={content.filters.headingHtml} />
          {hasPages && (
            <nav
              className="flex items-center shrink-0"
              aria-label={content.pagination.label}
            >
              <a
                href={`?page=${
                  clampedPage <= 1 ? clampedPage : clampedPage - 1
                }`}
                onClick={(e) => {
                  e.preventDefault();
                }}
                className="aria-[disabled]:cursor-auto aria-[disabled]:pointer-events-none"
                aria-disabled={clampedPage <= 1 ? 'true' : undefined}
                aria-label={content.pagination.previous}
              >
                <ChevronLeftIcon
                  className="inline-flex"
                  fill={clampedPage > 1 ? 'currentColor' : 'lightgray'}
                  onClick={
                    clampedPage > 1
                      ? () => handlePageClick(clampedPage - 1, false)
                      : undefined
                  }
                  width={24}
                  height={24}
                />
              </a>
              <span
                className="inline-flex items-center justify-center mx-2"
                aria-live="polite"
              >
                {content.pagination.pageOfPages
                  .replace('{{ currentPage }}', clampedNewPage.toString())
                  .replace('{{ totalPages }}', totalPages.toString())}
              </span>
              <a
                href={`?page=${
                  clampedPage >= totalPages ? clampedPage : clampedPage + 1
                }`}
                onClick={(e) => {
                  e.preventDefault();
                }}
                className="aria-[disabled]:cursor-auto aria-[disabled]:pointer-events-none"
                aria-disabled={clampedPage >= totalPages ? 'true' : undefined}
                aria-label={content.pagination.next}
              >
                <ChevronRightIcon
                  className="inline-flex"
                  fill={clampedPage < totalPages ? 'currentColor' : 'lightgray'}
                  onClick={
                    clampedPage < totalPages
                      ? () => handlePageClick(clampedPage + 1, false)
                      : undefined
                  }
                  width={24}
                  height={24}
                />
              </a>
            </nav>
          )}
        </div>
        <Filters
          onFilterChange={onFilterChange}
          filters={filtersWithSelected as FiltersType}
          labels={content.filters.labels}
        />

        {hasActiveFilters && (
          <div className="container">
            <p className="inline-block mr-2">
              {`${content.filters.results.showing} `}
              <strong>
                {filteredArticles.length} {content.filters.results.customers}
              </strong>
            </p>
            <button onClick={clearFilters} className="underline inline-block">
              {content.filters.clear}
            </button>
          </div>
        )}

        {/* Articles cards & links */}
        <CaseStudies
          articles={filteredArticles}
          newPage={clampedNewPage}
          page={clampedPage}
          totalPages={totalPages}
          urlUtils={urlUtils}
          handleTransitionEnd={handleTransitionEnd}
        />

        {hasPages && (
          <div className="container">
            <Pagination
              mode="light"
              currentPage={clampedPage}
              onPageChange={handlePageClick}
              paginationRange={paginationRange}
            />
          </div>
        )}
      </Section>
    </PageLayout>
  );
}
