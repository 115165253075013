import {ARTICLES_PER_PAGE} from '../constants';
import type {IndexArticlesQueryNodes} from '../typesGraphql';

export function getArticlesPageSlice(
  page: number,
  articles: IndexArticlesQueryNodes,
  perPage = ARTICLES_PER_PAGE,
) {
  const start = (page - 1) * perPage;
  return articles.slice(start, start + perPage);
}
