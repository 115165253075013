import type {SerializeFrom} from '@remix-run/server-runtime';

import type {ImageProps} from '@/components/base/elements/Image/Image';

import type {IndexArticlesQueryNodes} from '../typesGraphql';

import {getImageQueryParams} from './getImageQueryParams';
import {getSrcSet} from './getSrcSet';

/**
 * Use featured_image if provided
 */
export function getCardImageProps(
  article: SerializeFrom<IndexArticlesQueryNodes[0]>,
  index: number,
): ImageProps {
  const img: ImageProps = {
    className: 'aspect-square rounded-tl-2xl rounded-tr-2xl',
    src: '',
    alt: '',
    loading: index < 12 ? 'eager' : ('lazy' as ImageProps['loading']),
  };

  if (article.image?.url) {
    img.src = `${article.image.url}${getImageQueryParams(337, 337, 1)}`;
    img.sources = [
      {
        srcSet: getSrcSet(article.image.url, 607, 607),
        media: '(max-width: 639px)',
      },
      {
        srcSet: getSrcSet(article.image.url, 412, 412),
        media: '(min-width: 640px) and (max-width: 899px)',
      },
      {
        srcSet: getSrcSet(article.image.url, 356, 356),
        media: '(min-width: 900px) and (max-width: 1199px)',
      },
      {
        srcSet: getSrcSet(article.image.url, 337, 337),
        media: '(min-width: 1200px)',
      },
    ];
    img.alt = article.image.altText || '';
  }

  return img;
}
