import cn from 'classnames';
import {cva} from 'class-variance-authority';

import type {UrlUtils} from '@/utils/UrlUtils';
import Card from '@/components/base/modules/Card/Card';

import type {IndexArticlesQueryNodes} from '../typesGraphql';
import {getArticlesPageSlice} from '../utils/getArticlesPageSlice';
import {getCardImageProps} from '../utils/getCardImageProps';
import type {getFilteredArticles} from '../utils/getFilteredArticles';

type CaseStudiesProps = {
  articles: ReturnType<typeof getFilteredArticles>;
  newPage: number;
  page: number;
  totalPages: number;
  urlUtils: UrlUtils;
  handleTransitionEnd: () => void;
};

const styles = cva(
  [
    'container grid gap-gutter',
    'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4',
  ],
  {
    variants: {
      isCurrent: {
        true: 'relative transition-none z-10',
      },
      isCurrentTransitioning: {
        true: 'opacity-0 transition-opacity duration-200 ease-out',
      },
      isPrevOrNext: {
        true: [
          'case-studies-articles absolute',
          'top-0 left-margin xl:left-1/2 xl:-translate-x-1/2',
          'opacity-0 transition-none pointer-events-none',
        ],
      },
      isPrevOrNextTransitioning: {
        true: 'opacity-100 transition-opacity duration-200 ease-out',
      },
    },
  },
);

/**
 * Renders a paginated grid of case study cards. Functions similarly to a
 * carousel to improve performance by pre-rendering the previous and next pages.
 */
export function CaseStudies({
  articles,
  newPage,
  page,
  totalPages,
  urlUtils,
  handleTransitionEnd,
}: CaseStudiesProps) {
  return (
    <div className="relative">
      {Array.from({length: 3}).map((_, index) => {
        let pageSlice = 1;
        let isPrev = false;
        let isCurrent = false;
        let isNext = false;

        switch (index) {
          // Previous page
          case 0:
            pageSlice = page - 1;
            isPrev = pageSlice > 0;
            break;
          // Current page
          case 1:
            pageSlice = page;
            isCurrent = true;
            break;
          // Next page
          case 2:
            pageSlice = page + 1;
            isNext = pageSlice <= totalPages;
            break;
        }

        if (!isPrev && !isCurrent && !isNext) return null;

        return (
          <div
            key={
              isCurrent
                ? 'caseStudies-current'
                : `caseStudies-${isPrev ? 'prev' : 'next'}`
            }
            className={styles({
              isCurrent,
              isCurrentTransitioning: isCurrent && newPage !== page,
              isPrevOrNext: isPrev || isNext,
              isPrevOrNextTransitioning:
                (isPrev && newPage < page) || (isNext && newPage > page),
            })}
            onTransitionEnd={isCurrent ? handleTransitionEnd : undefined}
          >
            {getArticlesPageSlice(pageSlice, articles).map(
              (article: IndexArticlesQueryNodes[0], idx: number) => (
                <a
                  key={article.handle}
                  href={urlUtils.getUrl(`/case-studies/${article.handle}`)}
                  className={cn(
                    'rounded-2xl relative before:pointer-events-none before:absolute before:rounded-2xl',
                    'before:h-full before:w-full before:shadow-2xl before:opacity-0',
                    'before:transition-opacity before:duration-300 hover:before:opacity-100',
                    {'before:will-change-[opacity]': isCurrent},
                  )}
                  tabIndex={isPrev || isNext ? -1 : 0}
                >
                  <Card
                    className="case-studies-card bg-shade-10 h-full p-0"
                    headingGroup={{
                      headingHtml: `<span class="sr-only">${article.merchant.reference.name.value}</span><span class="case-studies-logo"><img class="h-auto" src="${article.merchant?.reference.logo?.reference.image.url}" alt="${article.merchant.reference.name.value}" /></span>`,
                      subheadHtml: article.excerptHtml,
                      className: 'p-md pt-xs',
                    }}
                    size="small"
                    type="outline"
                    image={getCardImageProps(article, idx)}
                  />
                </a>
              ),
            )}
          </div>
        );
      })}
    </div>
  );
}
