import Select from '@/components/base/modules/Select/Select';

import type {
  Filters,
  MarketsFilter,
  QueryMetaObject,
  QueryProduct,
} from '../typesGraphql';

interface FiltersProps {
  onFilterChange: (fieldName: string, value: string) => void;
  labels: Record<string, string>;
  filters: Filters;
}

type SortValue = QueryMetaObject | QueryProduct;

function sortValues(a: SortValue, b: SortValue) {
  if (a.title && b.title) {
    return a.title.localeCompare(b.title);
  }

  if (a.field && b.field) {
    return a.field.value.localeCompare(b.field.value);
  }

  return 0;
}

function createOptions(values: QueryMetaObject[] | QueryProduct[]) {
  return values.sort(sortValues).map((value) => {
    return (
      <option key={value.handle} value={value.handle}>
        {'title' in value ? value.title : value.field?.value}
      </option>
    );
  });
}

function createOptGroups(
  values: MarketsFilter['values'],
  labels: FiltersProps['labels'],
) {
  return (Object.keys(values) as (keyof typeof values)[]).map(
    (key, i) =>
      values[key] && (
        <optgroup
          key={i}
          label={labels[key === 'markets' ? 'countries' : 'regions']}
        >
          {createOptions(values[key])}
        </optgroup>
      ),
  );
}

export default function Filters({
  onFilterChange,
  filters,
  labels,
}: FiltersProps) {
  return (
    <div className="[&_label]:left-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 container justify-between">
      {filters.map((filter) => (
        <Select
          key={filter.handle}
          id={`${filter.handle}-select`}
          label={labels[filter.handle]}
          onChange={(event) =>
            onFilterChange(filter.handle, event.target.value)
          }
          className="[&_label]:left-4 px-4 border-black rounded shadow-light"
          value={filter.selected}
        >
          <option value="">{labels.all}</option>
          {Array.isArray(filter.values)
            ? createOptions(filter.values)
            : createOptGroups(filter.values, labels)}
          ,
        </Select>
      ))}
    </div>
  );
}
