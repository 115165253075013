import type {IndexArticlesQueryNodes} from '../typesGraphql';
import type {CountryRegionMap} from '../types';

import {isMerchantKey} from './typeUtils';

export function getFilteredArticles(
  initialArticles: IndexArticlesQueryNodes,
  searchParamsEntries: [string, string][],
  countryRegionMap: CountryRegionMap,
) {
  if (!initialArticles || initialArticles.length === 0) return [];
  if (searchParamsEntries.length === 0) return initialArticles;

  return initialArticles.filter((article) => {
    return searchParamsEntries.every(([key, value]) => {
      if (!article.merchant) return false;
      if (!value) return true;

      if (isMerchantKey(key, article.merchant)) {
        const entry = article.merchant.reference[key];

        if (!entry) return false;

        if ('reference' in entry) {
          return entry.reference.handle === value;
        }

        if ('references' in entry) {
          // If a region is selected from markets, we map that value to get the list of countries
          if (key === 'markets' && countryRegionMap.regions[value]) {
            return entry.references.edges.some((edge) =>
              countryRegionMap.regions[value].includes(edge.node.handle),
            );
          } else {
            return entry.references.edges.some(
              (edge) => edge.node.handle === value,
            );
          }
        }
      }

      return false;
    });
  });
}
